import {Component, OnDestroy} from "@angular/core";
import {PublicationType, PublicationTypeService} from "../../../api/core";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../shared/modal/modal.component";
import {
  AbstractControl,
  FormControl,
  FormGroupDirective, NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Subscription} from "rxjs";
import {EFormStatus} from "../../../util/enum";
import {NotificationService} from "../../../services/notification.service";

@Component({
  selector: 'app-publication-type-create',
  templateUrl: './publication-type-create.component.html'
})
export class PublicationTypeCreateComponent implements OnDestroy {

  identFormControl: FormControl = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  canCreate = false;
  // matcher to show error message immediately
  matcher = {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
  }
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly notificationService: NotificationService,
    private readonly publicationTypeService: PublicationTypeService,
    private readonly dialogRef: MatDialogRef<ModalComponent>,
  ) {
    this.subscriptions.push(
      this.identFormControl.statusChanges.subscribe((status) => {
        this.canCreate = (status === EFormStatus.VALID);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  create(): void {
    const ident = this.identFormControl.value;
    this.publicationTypeService.createNew(ident).subscribe({
      next: (data: PublicationType) => {
        this.dialogRef.close(data);
      },
      error: (error) => {
        this.notificationService.handleError(error);
      }
    });
  }
}
