import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  PublicationTypeCatalogueComponent
} from "./views/publication-type-catalogue/publication-type-catalogue.component";
import {PublicationTypeRoutingModule} from "./publication-type-routing.module";
import {PublicationTypeOverviewComponent} from "./views/publication-type-overview/publication-type-overview.component";
import {
  PublicationTypeMainDataComponent
} from "./shared/publication-type-main-data/publication-type-main-data.component";
import {
  PublicationTypeInternalCommsComponent
} from "./shared/publication-type-internal-comms/publication-type-internal-comms.component";
import {StoryModule} from "../story/story.module";
import {
  PublicationTypeSendControlsComponent
} from "./shared/publication-type-send-controls/publication-type-send-controls.component";
import {PublicationTypeSubjectComponent} from "./shared/publication-type-subject/publication-type-subject.component";
import {
  PublicationTypeClientCommsComponent
} from "./shared/publication-type-client-comms/publication-type-client-comms.component";
import {FroalaEditorModule, FroalaViewModule} from "angular-froala-wysiwyg";
import {
  PublicationTypeMonitoringComponent
} from "./shared/publication-type-monitoring/publication-type-monitoring.component";
import {PublicationTypeCreateComponent} from "./views/publication-type-create/publication-type-create.component";

/**
 * Product Module.
 * Contains all components related to products
 */
@NgModule({
  declarations: [
    PublicationTypeCatalogueComponent,
    PublicationTypeClientCommsComponent,
    PublicationTypeCreateComponent,
    PublicationTypeInternalCommsComponent,
    PublicationTypeMainDataComponent,
    PublicationTypeMonitoringComponent,
    PublicationTypeOverviewComponent,
    PublicationTypeSendControlsComponent,
    PublicationTypeSubjectComponent,
  ],
  imports: [
    CommonModule,
    PublicationTypeRoutingModule,
    SharedModule,
    StoryModule,
    FroalaEditorModule,
    FroalaViewModule,
  ],
  exports: [ PublicationTypeCatalogueComponent, PublicationTypeOverviewComponent ],
})
export class PublicationTypeModule {}
