<div class="page-content-container">
  <div class="catalogue-toolbar">
    <button
      mat-flat-button
      color="primary"
      (click)="createPublicationType()"
      *ngIf="protectedActions.publicationTypeEdit | protectedAction"
    >
      <app-config-icon
        iconIdentifier="open_in_new"
        class="color-white"
      ></app-config-icon>
      {{ 'publicationTypeCreate' | translate }}
    </button>
</div>
  <app-grid
    #grid
    tableId="publication-type-catalogue"
    [data]="data"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
  ></app-grid>
