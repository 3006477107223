import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageComponent} from 'src/app/shared/page/page.component';
import {AuthGuard} from 'src/app/util/auth.guard';
import {ERoutePaths, EViewRoutes, EViewTitles,} from 'src/app/util/enum';
import {PermissionGuard} from 'src/app/util/permission.guard';
import {PublicationTypeResolver} from 'src/app/util/resolvers';
import {EProtectedActions} from '../util/protected-actions';
import {
  PublicationTypeCatalogueComponent
} from "./views/publication-type-catalogue/publication-type-catalogue.component";
import {PublicationTypeOverviewComponent} from "./views/publication-type-overview/publication-type-overview.component";

/**
 * Publication Type route definition.
 * Contains all routing definitions related to products
 */
const publicationTypeRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ERoutePaths.all,
        component: PublicationTypeCatalogueComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: EViewTitles.publicationTypes,
          viewRoute: EViewRoutes.publicationTypeList,
          allowedRoles: [EProtectedActions.publicationTypeView],
        },
      },
      {
        path: ERoutePaths.overview,
        component: PublicationTypeOverviewComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: EViewTitles.publicationTypeOverview,
          viewRoute: EViewRoutes.publicationTypeView,
          allowedRoles: [EProtectedActions.viewProducts],
        },
        resolve: {
          publicationType: PublicationTypeResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(publicationTypeRoutes)],
  exports: [RouterModule],
})
export class PublicationTypeRoutingModule {}
