import {Component, OnDestroy, ViewChild} from "@angular/core";
import {
  CodeTableEntry,
  PublicationType,
  PublicationTypeService,
  PublicationTypeTimeFrame,
  UserInfo,
  UserService
} from "../../../api/core";
import {GlobalService} from "../../../services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../../services/notification.service";
import {PermissionService} from "../../../services/permission.service";
import {GridDataProvider} from "../../../shared/grid/data-source";
import {ColDef, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams} from "ag-grid-community";
import {EModalType, EViewRoutes} from "../../../util/enum";
import {
  genBooleanColumn,
  genCodeTableColumn,
  genDateColumn,
  genEnumColumn,
  genTextColumn,
  genUserEnumColumn,
  usernameValueLabel,
} from "../../../util/grid/grid-renderer.util";
import {EProtectedActions} from "../../../util/protected-actions";
import {Subscription} from "rxjs";
import {PublicationTypeData} from "../../publication-type-data";
import {genIconButtonColumn} from "../../../shared/grid/cell-renderers/icon-button.renderer";
import {ModalData} from "../../../models/modal.model";
import {ModalService} from "../../../services/modal.service";
import {PublicationTypeCreateComponent} from "../publication-type-create/publication-type-create.component";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../shared/modal/modal.component";
import {GridComponent} from "../../../shared/grid/grid.component";

@Component({
  selector: 'app-publication-type-catalogue',
  templateUrl: './publication-type-catalogue.component.html'
})
export class PublicationTypeCatalogueComponent implements OnDestroy {
  @ViewChild('grid') grid: GridComponent;
  gridApi: GridApi;
  data: GridDataProvider = this.publicationTypeService.getPubTypes.bind(
    this.publicationTypeService
  );
  hubs: CodeTableEntry[] = [];
  editors: UserInfo[] = [];

  columnDefs: ColDef[] = [
    this.getNameColumn(),
    {
      ...genCodeTableColumn({
        field: 'hubs',
        headerName: this.translateService.instant('hub'),
        filterValues: () => this.hubs,
        filterHubs: () => this.hubs.map(h => h.ident),
      }),
      valueGetter: (data) => {
        const hubs = data.data.hubs.map(h => h.ident);
        hubs.sort();
        return hubs.join(', ');
      },
      sortable: false,
      floatingFilter: false,
    },
    genDateColumn({
      field: 'createdAt',
      headerName: this.translateService.instant('creationDate'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.createdAt),
    }),
    genBooleanColumn(
      'closed',
      this.translateService.instant('closed'),
      this.translateService,
    ),
    {
      ...genUserEnumColumn(
        'lastEditor.username',
        this.translateService.instant('lastEditor'),
        this.fetchEditors.bind(this),
        () => this.editors
      ),
      valueFormatter: (r) => usernameValueLabel(r.data.lastEditor),
    },
    genIconButtonColumn({
      icon: 'view',
      tooltip: this.translateService.instant('publicationTypeView'),
      getRouterUrl: (data: PublicationType) => `${EViewRoutes.publicationTypeView}${data.id}`,
      getQueryParams: (data: PublicationType) => ({readonly: '1'})
    }),
    genIconButtonColumn({
      icon: 'edit_m',
      tooltip: this.translateService.instant('edit'),
      getRouterUrl: (data: any) => `${EViewRoutes.publicationTypeView}${data.id}`,
      hidden: (data: PublicationType) => this.publicationTypeData.isReadonly(data)
    }),
    genIconButtonColumn({
      icon: (data: PublicationType) => data.closed ? 'folder_open' : 'folder',
      tooltip: (data: PublicationType) => this.translateService.instant(data.closed ? 'publicationTypeOpen' : 'publicationTypeClose'),
      callback: (data: PublicationType) => this.onOpenClose(data),
      hidden: (data: PublicationType) => !this.permissionService.hasAnyPermission(
        data.closed
          ? EProtectedActions.publicationTypeOpen
          : EProtectedActions.publicationTypeClose)
    }),
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<any>) => (this.gridApi = event.api),
  };

  subscriptions: Subscription[] = [];

  constructor(
    readonly publicationTypeService: PublicationTypeService,
    readonly publicationTypeData: PublicationTypeData,
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    protected notificationService: NotificationService,
    protected permissionService: PermissionService,
    protected modalService: ModalService,
    protected userService: UserService,
  ) {
    this.subscriptions.push(
      permissionService.user$.subscribe(user => {
        this.hubs = [...user.hubs];
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get protectedActions() {
    return EProtectedActions;
  }

  getNameColumn() {
    const col = genTextColumn(
      'name',
      this.translateService.instant('publicationType'),
    );
    col.filterParams.customPath = this.translateService.currentLang != 'en' ? 'nameDe' : 'nameEn';
    return col;
  }

  getTimeFrameColumn() {
    const colDef = genEnumColumn({
      field: 'timeFrame',
      headerName: this.translateService.instant('publicationTypeTimeFrame'),
      values: Object.values(PublicationTypeTimeFrame),
    });
    const textFormatter = (params) => this.translateService.instant(this.publicationTypeData.timeFrameTranslationKey(params.value));
    colDef.filterParams.valueFormatter = textFormatter;
    colDef.valueFormatter = (params) => {
      const text = textFormatter(params);
      return this.publicationTypeData.timeFrameHasValue(params.value) ? `${text} (${params.data.timeValue})` : text;
    };
    return colDef;
  }

  createPublicationType() {
    const modalData: ModalData = {
      type: EModalType.confirmationDialog,
      title: this.translateService.instant('publicationTypeCreate'),
      data: {},
      component: PublicationTypeCreateComponent,
    };
    this.modalService.openConfirmationDialog(modalData).afterClosed()
      .subscribe((result?: PublicationType) => {
      if (result) {
        const route = `${EViewRoutes.publicationTypeView}${result.id}`;
        this.globalService.navigate(route);
      }
    });
  }

  private fetchEditors(params: any) {
    this.userService
      .listUsersByPermission(EProtectedActions.publicationTypeEdit)
      .subscribe((data) => {
        this.editors = data;
        params.success(data.map((d) => d.username));
      });
  }

  private onOpenClose(data: PublicationType) {
    if (data.closed) {
      this.onOpen(data);
    } else {
      this.onClose(data);
    }
  }

  private onOpen(data: PublicationType) {
    const currentUsername = this.permissionService.currentUsername;
    if (currentUsername == data.lastEditor?.username) {
      return this.onOpenLastEditor(data);
    }
    const modalData: ModalData = {
      title: this.translateService.instant('publicationTypeOpenTitle'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('publicationTypeOpenMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('publicationTypeOpen'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.publicationTypeService.editPageOpen(data.id).subscribe({
            next: () => {
              modalRef.close();
              this.grid.reload();
              this.notificationService.handleSuccess(
                this.translateService.instant('publicationTypeOpenSuccess')
              );
            },
          });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }
  private onOpenLastEditor(data: PublicationType) {
    const modalData: ModalData = {
      title: this.translateService.instant('publicationTypeOpenCloseTitle'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('publicationTypeOpenCloseMessage'),
      },
      component: null,
      cancelBtn: {
        label: this.translateService.instant('close'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }
  private onClose(data: PublicationType) {
    const modalData: ModalData = {
      title: this.translateService.instant('publicationTypeCloseTitle'),
      type: EModalType.confirmationDialog,
      data: {
        message: this.translateService.instant('publicationTypeCloseMessage'),
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant('publicationTypeClose'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.publicationTypeService.editPageClose(data.id).subscribe({
            next: () => {
              modalRef.close();
              this.grid.reload();
              this.notificationService.handleSuccess(
                this.translateService.instant('publicationTypeCloseSuccess')
              );
            },
          });
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    };
    this.modalService.openConfirmationDialog(modalData);
  }
}
