<mat-form-field>
  <mat-label>{{ 'publicationTypeNameEn' | translate }}</mat-label>
  <input matInput [formControl]="identFormControl" [errorStateMatcher]="matcher" placeholder="{{'publicationTypeNameEn' | translate}}" required>
  @if (identFormControl.hasError('required')) {
    <mat-error>{{ 'inputRequired' | translate}}</mat-error>
  }
  @if (identFormControl.hasError('uniqueIdent')) {
    <mat-error>{{ 'identAlreadyExists' | translate}}</mat-error>
  }
  @if (identFormControl.hasError('maxlength')) {
    <mat-error>{{ 'inputTooLongSimple' | translate}}</mat-error>
  }
</mat-form-field>
<mat-dialog-actions align="center">
  <button mat-stroked-button mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button mat-flat-button color="primary" (click)="create()" [disabled]="!canCreate">{{ 'create' | translate }}</button>
</mat-dialog-actions>

